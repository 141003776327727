import { render, staticRenderFns } from "./ListDealCompanies.vue?vue&type=template&id=5063448e&scoped=true"
import script from "./ListDealCompanies.vue?vue&type=script&lang=js"
export * from "./ListDealCompanies.vue?vue&type=script&lang=js"
import style0 from "./ListDealCompanies.vue?vue&type=style&index=0&id=5063448e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5063448e",
  null
  
)

export default component.exports