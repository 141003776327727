<template>
  <v-container>
    <v-card width="100%" height="auto"  class="mx-auto ma-2 pa-2  rounded-lg">
      <v-card outlined height="auto" class="pa-1 elevation=3">
         <v-layout >
          <v-radio-group dense outlined row style="margin-top: 1px; margin-bottom: -18px"
              id="selOption" 
              ref="selOption" 
              v-model="selOption"
            >
              <v-radio
                label="입식처" value="enter" color="blue" @click="listCompanies() "
              ></v-radio>
              <v-radio
                label="출하처" value="sale" color="green"  @click="listCompanies()"
              ></v-radio>
              <v-radio
                label="도축처" value="slaughter" color="red"  @click="listCompanies()"
              ></v-radio>
              <v-radio
                label="사료" value="feed" color="cyan"  @click="listCompanies()"
              ></v-radio>
              <v-radio
                label="약품" value="medi" color="gray"  @click="listCompanies()"
              ></v-radio>
              <v-radio
                label="방역" value="fumi" color="orange"  @click="listCompanies()"
              ></v-radio>
              <v-radio
                label="기타" value="etc" color="black"  @click="listCompanies()"
              ></v-radio>
              <v-radio
                label="모두" value="all" color="#3a4f3f"  @click="listCompanies()"
              ></v-radio>
          </v-radio-group>
        </v-layout>
      </v-card>      
      <v-data-table dense height="540" class="mt-2 elevation-3" 
        :loading="loadingTable" 
        :headers="headers"
        :items="companies"
        no-data-text="등록된 자료가 없거나 접근 권한이 없습니다."
        id="companies" 
        ref="companies" 
        @click:row="selectRow"
        @dblclick:row="selectedBizNo.business_no && detailInfo(selectedBizNo)"
        item-key="business_no"
        single-select
        :item-class="itemRowBackground"
        fixed-header
        hide-default-footer
        :items-per-page= "500"
        sort-by="company_name"
      >
      <template v-slot:item.actions="{ item }">
          <v-icon
            color=#3a4f3f
            large
            @click="detailInfo(item)"
          >
            mdi-pencil-box
          </v-icon>
        </template>
      </v-data-table>
        <v-row justify= "center" class="mx-auto ma-2 pa-2  rounded-lg">
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn class="rounded-pill elevation-7 normal-btn"
          @click="selectedBizNo.business_no && detailInfo(selectedBizNo)">
          상세조회
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn class="rounded-pill elevation-7 new-btn" 
        href="/farminfo/dealcompany">
          신규등록
        </v-btn>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
      </v-row>
    </v-card>
 </v-container>
</template>

<script>
  import Apis from '@/api/apis'
  import Common from "@/utils/custom/common.js";
  import dateUtil from "@/utils/custom/dateProperty.js";

  export default {
    name: 'ListDealCompanies',

  created() {
    Apis.pageCount({
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
      // console.log("pageCount 호출",res);
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });
    // console.log("recieive=",this.$route.params);
    if (!this.isNull(this.$route.params)) {
      this.routedBizNo = this.$route.params.business_no;
      this.selOption = this.$route.params.selOption;
    }
    
    this.listCompanies();
  },

  data() {
    return {
      headers: [
        { text: '',align: 'center', sortable: false, value: 'actions', width: "10px", groupable: false, class: 'green darken-4 white--text' },
        { text: '상호', align: 'left', sortable: true, value: 'company_name', width: "110px", class: 'green darken-4 white--text' },
        { text: '등록번호', value: 'business_no', sortable: true, width: "110px", class: 'green darken-4 white--text' },
        { text: '대표', value: 'ceo_name', sortable: true, width: "85px", class: 'green darken-4 white--text' },
        { text: '전화번호', value: 'phone1', width: "110px", class: 'green darken-4 white--text' },
        { text: '거래 시작일', value: 'dealdate', sortable: false, width: "110px", class: 'green darken-4 white--text' },
        { text: '거래 중지일', value: 'deldate', sortable: false, width: "110px", class: 'green darken-4 white--text' },
        { text: '주소', value: 'address_1st', sortable: false, width: "250px", class: 'green darken-4 white--text' },
        { text: '이메일', value: 'email1', sortable: false, width: "30px", class: 'green darken-4 white--text' },
        { text: '비고', value: 'history', sortable: false, width: "300px", class: 'green darken-4 white--text' },
        { text: '등록일시', value: 'regdate', sortable: false, width: "120px", class: 'green darken-4 white--text' },
      ],
      companies : [],
      loadingTable: false,

      selOption: "all",
      enter: false,
      sale: false,
      slaughter: false,
      feed: false,
      medi: false,
      fumi: false,
      etc: false,

      selectedBizNo: {},
      routedBizNo: "",

    }
  },
  methods: {
    listCompanies() {
      this.$store.commit("resMessage","");
      this.loadingTable = true;
      Apis.listDealCompanies({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"),
        dealtype: this.selOption,
      } ,(res) => {  // 정상처리
        // console.log("res",res);
        res.data.forEach(element => {
          element.business_no = Common.getRegMask(element.business_no);
          element.phone1 = Common.getMask(element.phone1);
          element.address_1st = element.address_1st + "" + element.address_last
          element.service = element.service == "5" ? "프리미엄" : "일반" ;
        });
        this.companies = res.data;
        this.$store.commit("resMessage",res.message);
        this.loadingTable = false;
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("listCompanies 호출 오류",err);
        this.$router.push("/");
        this.loadingTable = false;
      }) 
    }, // -----------listCompanies
    selectRow(item,row) {
      row.select(true);
      // console.log("item-",item,"row=,",row)
      this.selectedBizNo = item;
      this.routedBizNo = "";
    },
    detailInfo(value) {
      // console.log("상세조회",value.company_cd);
      // alert(value.company_name + "의 정보를 상세조회합니다")
      this.loadingTable = true;
      this.$router.push({
                        name: "DealCompany",
                        params: { company_cd: value.regcompany_cd, business_no: value.business_no.replace(/[^0-9]/g, ''), selOption: this.selOption}
                       });
      this.loadingTable = false;
    }, // -----------detailInfo
    itemRowBackground(item) {
      return item.business_no == this.routedBizNo  ? 'selected' : '' ;
    },
    },  // -----------methods 
  }
</script>

<style lang="scss" scoped>
tr.v-data-table__selected {
  background: #edfcf0 !important;
}
.selected {
  background-color: #edfcf0;
}
.normal-btn{
  vertical-align: middle !important;
  color:white ;
  font-size:12pt;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}
.new-btn{
  color:#3a4f3f !important;
  font-size:12pt;
  font-weight: bold !important;
  background-color: rgb(248, 248, 248) !important;
}
.dialog-btn{
  color:white !important;
  font-size:12pt;
  font-weight: bold !important;
  background-color: #88191a !important;
}
</style>
